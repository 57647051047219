<template>
  <div class="lucky-number-form">

    <div>
        <img alt="Vue logo" src="../assets/logo.png" class="logo">
    </div>

    <!-- Collapsible Text Section -->
    <div class="collapsible-text">
        <p>
          Believe in destiny? <br><br>Motosuke Uesaka, a Japanese man who won lotteries worth over $5 million in total, claiming prizes 15 times over 40 years. <br><br>His method? ...
          <!-- Toggle visibility for the rest of the content -->
          <span v-if="isExpanded">
            Not mathematical hacking, but leveraging Feng Shui, horoscopes, and divination to find his lucky numbers, directions, and dates, strategically purchasing lottery tickets with these insights.
            <br><br>
            Inspired by Motosuke's approach, we applied AI to analyze various divination methods across cultures and religions, uncovering patterns and similarities. This led to the development of the Luck Hacker algorithm, refined through analysis of extensive data from public figures and lottery winners.
            <br><br>
            Now ready for public use, the Luck Hacker algorithm offers a unique way to choose when and where to buy lottery tickets, suggesting specific directions and dates. As it continues to evolve with new data, its accuracy only improves. 
            <br><br>
            Why not give it a try? If you're playing the lottery, consider using the Luck Hacker's recommendations for your next purchase.
          </span>
        </p>
        <button @click="toggleText" class="read-more-button">
          {{ isExpanded ? 'Read Less' : 'Read More' }}
        </button>
      </div>

    <form @submit.prevent="calculateLuckyNumber">

      <!-- Label and Date of Birth Input -->
      <input id="dateOfBirth" v-model="dateOfBirth" type="date" placeholder="1995-10-21" class="date-input">
      <label for="dateOfBirth" id="dateOfBirth-label">Date of birth (*required)</label>

      <!-- Time of Birth Input -->
      <div class="time-of-birth">
        <select v-model="selectedHour">
          <option v-for="hour in hours" :key="hour" :value="hour">{{ hour }}</option>
        </select>
        <select v-model="selectedMinute">
          <option v-for="minute in minutes" :key="minute" :value="minute">{{ minute }}</option>
        </select>
        <select v-model="selectedAmPm">
          <option value="AM">AM</option>
          <option value="PM">PM</option>
        </select>
        <label for="timeOfBirth" id="timeOfBirth-label">Approximate time of birth <br>(optional if you don't know)</label>
      </div>
      

      <!-- Searchable Dropdown for City -->
      <div class="search-box">
        <input
          type="text"
          v-model="searchTerm"
          @input="debouncedFilterList"
          placeholder="Search your city of birth"
        />
        <select v-if="searchTerm" v-model="selectedCity">
          <option disabled value="" selected>Select your city of birth</option>
          <option v-for="city in filteredCities" :key="city" :value="city">{{ city }}</option>
        </select>
        <label for="cityOfBirth">City of Birth (*required)</label>
      </div>

      <!-- Email Address Input -->
      <div class="email-address">
        <input v-model="emailAddress" type="email" placeholder="Enter your email address">
        <label for="email">(Optional, enter if you want to get notified when a 90+ lucky day is coming)<br></label>
      </div>

      <!-- Submit Button -->
      <button type="submit">Find My Lucky Number</button>
    
      <!-- Display Lucky Number -->
      <label id="result-label" v-if="luckyNumber">Your Lucky Number of the Month is: {{ luckyNumber }} <br>(this changes every month)</label>
      <label id="result-label" v-if="luckyDirection">Your Lucky Direction of the Month is: {{ luckyDirection }}<br>(this is relative to your long term home, it changes every month)</label>
      
      <!-- Calendar View for Lucky Indexes -->
      <label id="result-label" v-if="luckyIndexes.length">Your Luckiness Score for Today is:</label>
        <div v-if="luckyIndexes.length" class="calendar-view">
          <div class="calendar-day">
            <div class="date">{{ getFormattedDate(0) }}</div> <!-- Show today's date -->
            <div class="index">{{ luckyIndexes[0] }}</div> <!-- Show only the first lucky index -->
          </div>
        </div>

      <!-- Explanation on the Lucky Indexes -->
      <label id="result-label" v-if="luckyIndexes.length">
        0~20: Very Bad Day
        <br> 
        21~40: Bad Day
        <br>
        41~60: Okay Day
        <br>
        61~80: Good Day
        <br>
        91~100: Go Buy a Lottery Ticket!
        <br>
        <br>
        Subcribe with your email address for free, and never miss a 90+ lucky day!
      </label>

    </form>

    

    <p v-if="errorMessage" class="error-message">{{ errorMessage }}</p>

  </div>
</template>

<script>
import axios from 'axios';
import citiesData from './worldcities.json'; // Ensure this path is correct

export default {
  data() {
    return {
      dateOfBirth: '1995-10-21',
      selectedHour: '01',
      selectedMinute: '00',
      selectedAmPm: 'AM',
      emailAddress: '',
      luckyNumber: null,
      luckyDirection: null,
      searchTerm: '',
      selectedCity: '',
      citiesList: citiesData.map(city => city.city_ascii),
      filteredCities: [],
      debounceTimeout: null,
      luckyIndexes: [], // Array to store lucky indexes
      errorMessage: '',
      isExpanded: false, // Controls the visibility of the extended text
    };
  },
  computed: {
    hours() {
      return Array.from({ length: 12 }, (_, i) => (i + 1).toString().padStart(2, '0'));
    },
    minutes() {
      return ['00', '15', '30', '45'];
    },
    timeOfBirth() {
      let hour = parseInt(this.selectedHour);
      if (this.selectedAmPm === 'PM' && hour < 12) hour += 12;
      if (this.selectedAmPm === 'AM' && hour === 12) hour = 0;
      return hour.toString().padStart(2, '0') + this.selectedMinute;
    },
  },
  methods: {
    toggleText() {
      this.isExpanded = !this.isExpanded;
    },
    filterList() {
      if (this.searchTerm) {
        this.filteredCities = this.citiesList.filter(city =>
          city.toLowerCase().includes(this.searchTerm.toLowerCase())
        );
      } else {
        this.filteredCities = [];
      }
    },
    debouncedFilterList() {
      clearTimeout(this.debounceTimeout);
      this.debounceTimeout = setTimeout(() => {
        if (this.searchTerm) {
          this.filteredCities = this.citiesList.filter(city =>
            city.toLowerCase().includes(this.searchTerm.toLowerCase())
          );
        } else {
          this.filteredCities = [];
        }
        this.selectedCity = ''; // Reset the selected city when search term changes
      }, 300);
    },
    async calculateLuckyNumber() {
      const cityData = citiesData.find(city => city.city_ascii === this.selectedCity);
      const postData = {
        dateOfBirth: this.dateOfBirth,
        timeOfBirth: this.timeOfBirth,
        emailAddress: this.emailAddress,
        cityName: this.selectedCity,
        lat: cityData ? cityData.lat : null,
        lng: cityData ? cityData.lng : null,
      };

      try {
        const response = await axios.post('/calculate-lucky-number', postData);
        this.luckyNumber = response.data.luckyNumber;
        this.luckyDirection = response.data.luckyDirection;

        this.luckyIndexes = [
          response.data.luckyIndex1,
          //response.data.luckyIndex2,
          //response.data.luckyIndex3,
          //response.data.luckyIndex4,
          //response.data.luckyIndex5,
          //response.data.luckyIndex6,
          //response.data.luckyIndex7,
        ];

        this.errorMessage = ''
      } catch (error) {
        this.errorMessage = error.response && error.response.data && error.response.data.error
                        ? error.response.data.error
                        : 'An unknown error occurred.';
      }
    },

    getFormattedDate(daysToAdd) {
    const date = new Date();
    date.setDate(date.getDate() + daysToAdd);
    const month = String(date.getMonth() + 1).padStart(2, '0'); // JavaScript months are 0-indexed
    const day = String(date.getDate()).padStart(2, '0');
    return `${month}-${day}`; // Format as MM-DD
    },
  }
};
</script>

<style scoped>
  /* Add your CSS styling here */

/* Style for the date of birth input */
.date-input {
  width: auto; /* Adjust the width as needed */
  max-width: 200px; /* Example max-width; adjust as needed */
  margin-bottom: 10px; /* Adjust the space as needed */
}

/* Optional: Style for the time of birth section */
.time-of-birth {
  margin-top: 30px; /* Adjust the space as needed */
  margin-bottom: 10px;
}

.search-box {
  margin-top: 30px; /* Adjust the space as needed */
  margin-bottom: 10px;
}

.email-address {
  margin-top: 30px; /* Adjust the space as needed */
  margin-bottom: 30px;
}

/* Style for the label */
label {
  display: block;
  margin-bottom: .5rem; /* Reduced space below label for tighter grouping with inputs */
  color: #4CAF50; /* Adjusted label color for better visibility */
  font-size: 14px; /* Smaller font size for labels */
  text-align: left; /* Aligns text to the left */
  width: 100%; /* Ensures label width matches input width */
  font-weight: bold; /* Makes label text bolder */
}

#result-label {
  margin-top: 1rem; /* Adds space above the labels for spacing */
  text-align: left;
}


.lucky-number-form {
  max-width: 500px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f9f9f9;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  font-family: 'Arial', sans-serif;
  text-align: left;
  /* Additional styles for layout */
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* Style for inputs and selects */
.lucky-number-form input, 
.lucky-number-form select {
  width: auto; /* or specific width */
  max-width: 100%; /* Ensures elements don't overflow the form */
  margin-bottom: 10px;
  padding: 10px 15px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
}

.lucky-number-form button {
  background-color: #4CAF50;
  color: white;
  padding: 10px 15px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s;
}

.lucky-number-form button:hover {
  background-color: #45a049;
}

.lucky-number-form .error-message {
  color: red;
  margin-top: 10px;
  text-align: left;
}

/* Additional styles for lucky number and direction display */
p {
  color: #333;
  font-size: 18px;
}

.calendar-view {
  margin-top: 1rem;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(60px, 1fr)); /* Adjust minmax values based on your layout */
  gap: 20px; /* Spacing between grid items */
  width: 100%; /* Ensure the grid does not exceed the form's width */
  padding: 0; /* Remove padding if any */
}

.calendar-day {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px;
  border: 1px solid #ccc; /* Border for each grid item */
  border-radius: 4px; /* Rounded corners */
  text-align: center; /* Center the text inside the box */
}

.calendar-day .date {
  font-weight: bold;
}

.calendar-day .index {
  font-size: large;
}

.shaded {
  background-color: #f0f0f0; /* Light gray background for shaded grid items */
}

.logo {
  width: 100%; /* Full width of the container */
  max-width: 500px; /* Adjust to match the outer width of lucky-number-form */
  height: auto; /* Maintain aspect ratio */
  margin-bottom: 50px; /* Optional spacing between logo and form */
}

.collapsible-text p {
  margin-bottom: 1rem;
  color: #4CAF50; /* Adjusted label color for better visibility */
  font-size: 14px; /* Smaller font size for labels */
  font-weight: bold;
}

.read-more-button {
  background: none;
  color: #007bff;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  margin-bottom: 3rem;
}

</style>
