<template>
  <div class="content">
    <lucky-number-form></lucky-number-form>
  </div>
</template>

<script>
import LuckyNumberForm from './components/LuckyNumberForm.vue';

export default {
  name: 'App',
  components: {
    LuckyNumberForm
  },
  created() {
    document.title = "Luck Hacker";
  }
}
</script>

<style>
.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin: 0 auto;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  margin-top: 5px;
}



.lucky-number-form {
  width: calc(100%); /* Adjust if lucky-number-form has padding */
  max-width: 500px; /* Same as logo to match the width */
}
</style>
